import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { Checkbox } from 'libs.nucleus.checkbox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ListInformation, PageHeader, PageWrapper } from 'libs.react.components';
/**
 * TODO: to be re-factorized in future once we improve the flag context to be reactive
 */
const DeveloperFlagsPage = () => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const flagManager = useContext(FlagContext);
    const [flagsChanged, setFlagsChanged] = useState(false);
    const getFlagSectionName = (flag) => {
        const { extra = {} } = flag;
        return String(extra.section) || translate('General');
    };
    /**
     * We convert the flags on the flagManager to a local object as the current flag context is not reactive as needed
     */
    const getInitialFlags = () => {
        const initialFlags = flagManager.getFlags();
        return Object.keys(initialFlags).reduce((acc, name) => {
            var _a, _b;
            const flag = initialFlags[name];
            const sectionName = getFlagSectionName(flag);
            const previousSection = (_a = acc[sectionName]) !== null && _a !== void 0 ? _a : [];
            const updatedFlag = Object.assign(Object.assign({}, initialFlags[name]), { value: flagManager.getValue(flag) });
            return Object.assign(Object.assign({}, acc), { [sectionName]: ((_b = flag.extra) === null || _b === void 0 ? void 0 : _b.isFirst) ? [updatedFlag, ...previousSection] : [...previousSection, updatedFlag] });
        }, {});
    };
    const [flagSections, setFlagSections] = useState(getInitialFlags());
    /**
     * Re-assigns all the flags to the local state adding the new value for the modified flag
     */
    const onFlagChange = (flag) => {
        setFlagsChanged(true);
        const section = getFlagSectionName(flag);
        setFlagSections((oldFlags) => (Object.assign(Object.assign({}, oldFlags), { [section]: oldFlags[section].map((sectionFlag) => sectionFlag.name === flag.name ? Object.assign(Object.assign({}, sectionFlag), { value: !sectionFlag.value }) : sectionFlag) })));
    };
    /**
     * Triggered when finished editing the flags, it saves the new values on the flag manager
     */
    const saveFlags = () => {
        const managerFlags = flagManager.getFlags();
        Object.keys(flagSections).forEach((section) => {
            flagSections[section].forEach((flag) => {
                const foundFlag = Object.keys(managerFlags).find((flagName) => managerFlags[flagName].id === flag.id);
                if (foundFlag) {
                    flagManager.setValue(managerFlags[foundFlag], flag.value);
                }
            });
        });
        // Refresh the page to update the flags
        navigate(0);
    };
    const sections = useMemo(() => {
        return Object.keys(flagSections).map((section) => {
            const sectionItems = flagSections[section].map((flag) => ({
                title: String(flag.name) || translate('Unnamed Flag'),
                description: flag.description,
                actionContent: _jsx(Checkbox, { checked: flag.value, onChange: () => onFlagChange(flag), label: '' }),
                dataTestId: flag.extra.dataTestId,
            }));
            return { title: section, content: _jsx(ListInformation, { items: sectionItems }) };
        });
    }, [flagSections]);
    const headerOptions = [
        { name: translate('Save'), disabled: !flagsChanged, onClick: saveFlags, variant: 'primary' },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Developer flags'), options: headerOptions }), _jsx("div", { className: 'flex flex-col h-full space-between flex-start', children: _jsx("div", { className: 'flex-1 overflow-auto', children: _jsx(FormSections, { sections: sections }) }) })] }));
};
export default DeveloperFlagsPage;
